import { HttpRequestUserSpaceData } from "components/flow/type";
import { keys, filter, has, size, includes, isEmpty, map, findIndex, mapKeys, reduce, uniq } from "lodash";
import { flowData, subFlowsUsageData } from "./type";
import { ERRORS_RELATED_VARIABLES, FLOW_STATUS, FLOW_CREATE, FLOW_FETCH, FLOW_DELETE, FLOW_UPDATE, FLOW_SEARCH, FLOW_FILTER_TRIGGER, NODES_GET, NODES_UPDATE, NODES_CONNECTED_VARS, NODES_NOTIFIED, NODES_CONNECTED_JUMP, NODES_UPDATE_CONNECTED_JUMP, NODES_DELETE_CONNECTED_JUMP, NODES_UPDATE_CONNECTED_VARS, DELETE_RULE_UPDATE_CONNECTED_VARS, GET_HTTP_REQUEST_USER_SPACE, UPDATE_HTTP_REQUEST_USER_SPACE, UPDATE_SHOW_DRAWER, UPDATE_SELECTED_CONDITION, NODES_DELETE_GROUP_CONNECTED_JUMP, NODES_FIELDS_ERRORS, UPDATE_NODES_FIELDS_ERRORS, UPDATE_NODES_FIELDS_ERRORS_REPLY, ADD_FIELDS_ERRORS_REPLY, FLOW_UPDATE_STATUS, UPDATE_FLOW_OPTIONS, FLOW_CLEAR, GET_ORDERED_NODES, UPDATE_ORDERED_NODES, CUSTOM_STOP_LOADING, CUSTOM_LOADING, DISPLAY_ERROR_MODAL, STOP_DISPLAY_ERROR_MODAL, UPDATE_ALL_VARIABLES, ADD_HTTP_REQUEST_USER_SPACE, DELETE_ERRORS_RELATED_VARIABLES, FETCH_SUB_FLOWS, DELETE_SUB_FLOW, ADD_SUB_FLOW, EDIT_SUB_FLOW, NODES_CONNECTED_SUB_FLOW, UPDATE_NODES_CONNECTED_SUB_FLOW, DELETE_NODES_CONNECTED_SUB_FLOW, DISPLAY_TEMPLATE_MODAL, STOP_DISPLAY_TEMPLATE_MODAL, UPDATE_ORDERED_NODES_SUB_FLOWS } from "./types";
import { typeVariable } from "enums/flow";
import * as types from "components/flow/flow-editor/tabPanelFlowEditor/NodeTypes";
import { getNodesHadeErrorsRelatedVars, handleVarFound, onExtractErrors } from "components/flow/utils";

interface IntialState {
  list: flowData[];
  originalList: flowData[];
  currentFlow: flowData | null;
  subFlows: any,
  nodesConnectedWithSubFlows: any,
  customObjNodes: any,
  nodesConnectedWithSystemVars: Record<string, any>;
  notifiedNodes: any;
  nodesConnectedWithJump: Record<string, any>;
  httpRequestUserSpace: Record<string, HttpRequestUserSpaceData>;
  showDrawer: { enable: boolean; node: Node };
  selectedCondition: string;
  errorsRelatedVariables: any,
  errorsFieldsNodes: {
    [key in string]:
    { [key in string]: string }
  },
  currentFlowOptions: {
    isProcessed: boolean;
    isUsedEndCondition: boolean;
  } | null,
  orderedNodesObj: null | {
    [nodeId: string]: {
      index: number
      parentId: number,
      subFlowNodesId?: {
        [nodeId: string]: {
          index: number
          parentId: number
        }
      }

    }
  },
  allVariables: any,
  customLodaer: boolean,
  isDisplayErrorModal: boolean
  isDisplayTemplateModal: boolean
}

const initialState: IntialState = {
  list: [],
  originalList: [],
  currentFlow: null,
  subFlows: null,
  nodesConnectedWithSubFlows: {},
  customObjNodes: {},
  nodesConnectedWithSystemVars: {}, // {varId:{nodeId:[nodeId or ruleid]}}
  notifiedNodes: [],
  nodesConnectedWithJump: {},
  httpRequestUserSpace: {},
  showDrawer: { enable: false, node: {} as Node },
  selectedCondition: "",
  errorsRelatedVariables: {},
  errorsFieldsNodes: {},
  currentFlowOptions: null,
  orderedNodesObj: null,
  allVariables: {},
  customLodaer: false,
  isDisplayErrorModal: false,
  isDisplayTemplateModal: false
};

export default function authReducer(
  state = initialState,
  action: { type: any; payload: any }
) {
  const { type, payload } = action;
  switch (type) {
    case FLOW_STATUS: {
      const sortedList = (payload?.list || []).sort((a: flowData, b: flowData) => {
        const dateA = new Date(a?.CreatedAt);
        const dateB = new Date(b?.CreatedAt);
        // Sort in descending order (newest to oldest)
        return dateB.getTime() - dateA.getTime();
      });
      return {
        ...state,
        list: sortedList,
        originalList: payload.list,
      };
    }
    case FLOW_SEARCH: {
      return {
        ...state,
        list: filter([...state?.originalList], (item) => (item.Name.toLowerCase()).includes(payload.toLowerCase())),
      };
    }
    case FLOW_FILTER_TRIGGER: {
      let res = []
      if (payload.length === 0) {
        res = [...state?.originalList]
      } else {
        res = filter([...state?.originalList], (item) => payload.includes(item?.Trigger?.split("-")[1]))
      }
      return {
        ...state,
        list: res,
      };
    }
    case FLOW_CREATE: {
      return {
        ...state,
        list: [payload, ...state?.list]
      };
    }
    case FLOW_FETCH: {
      return {
        ...state,
        currentFlow: payload,
        isRefreshDuplicatedHttpVars: false
      };
    }
    case FLOW_DELETE: {
      return {
        ...state,
        list: filter(state.list, (item: flowData) => item?.Key !== payload)
      };
    }
    case FLOW_UPDATE: {
      return {
        ...state,
        list: [...state.list].map((item: flowData) => item.Key === payload?.Key ? Object.assign({}, payload, {}) : item),
      };
    }
    case FLOW_UPDATE_STATUS: {
      return {
        ...state,
        currentFlow:payload
      };
    }
    case FETCH_SUB_FLOWS: {

      return {
        ...state,
        subFlows: reduce(
          payload,
          (subFlowObj: any, param: any) => {
            subFlowObj[param.id] = param;
            return subFlowObj;
          },
          {}
        )
      }
    }
    case DELETE_SUB_FLOW: {
      let resOrderedNodesObj = { ...state.orderedNodesObj }
      delete resOrderedNodesObj?.[payload]
      let subFlows = { ...state.subFlows }
      delete subFlows?.[payload]
      let resNodesConnectedWithSubFlows: { [subFlowId: string]: subFlowsUsageData } = { ...state.nodesConnectedWithSubFlows }
      map(resNodesConnectedWithSubFlows?.[payload]?.callee, (flowId: string) => {
        resNodesConnectedWithSubFlows[flowId] = {
          ...resNodesConnectedWithSubFlows[flowId],
          caller: filter(resNodesConnectedWithSubFlows[flowId]?.caller, (i) => i !== payload)
        }
      })
      delete resNodesConnectedWithSubFlows[payload]
      return {
        ...state,
        subFlows,
        nodesConnectedWithSubFlows: resNodesConnectedWithSubFlows,
        orderedNodesObj: resOrderedNodesObj
      }
    }
    case ADD_SUB_FLOW: {
      return {
        ...state,
        subFlows: {
          ...state.subFlows, ...payload
        }
      }
    }
    case EDIT_SUB_FLOW: {
      return {
        ...state,
        subFlows: {
          ...state.subFlows,
          [payload?.id]: { ...payload }
        }
      }
    }
    case FLOW_CLEAR: {
      return {
        ...state,
        currentFlow: null,
        subFlows: null,
        currentFlowOptions: null,
        selectedCondition: "",
        errorsRelatedVariables: {},
        errorsFieldsNodes: {},
        orderedNodesObj: null,
        allVariables: {},
        customLodaer: false,
        isDisplayErrorModal: false,
        customObjNodes: {},
        nodesConnectedWithSystemVars: {}, // {varId:{nodeId:[nodeId or ruleid]}}
        notifiedNodes: [],
        nodesConnectedWithJump: {},
        nodesConnectedWithSubFlows: {},
        httpRequestUserSpace: {},
        showDrawer: { enable: false, node: {} as Node }
      };
    }
    case NODES_GET: {
      return {
        ...state,
        customObjNodes: payload,
      };
    }
    case ERRORS_RELATED_VARIABLES: {
      return {
        ...state,
        errorsRelatedVariables: payload,
      };
    }
    case DELETE_ERRORS_RELATED_VARIABLES: {
      let res = { ...state.errorsRelatedVariables }
      map(payload, (item) => {
        delete res?.[item]
      })
      return {
        ...state,
        errorsRelatedVariables: { ...res },
      };
    }
    case NODES_UPDATE: {
      // let id: number = payload?.id || 0
      // let targetNode={...state.customObjNodes[id]}
      // delete targetNode?.data
      // targetNode["data"]=payload?.data
      let id: number = payload?.id || 0
      return {
        ...state,
        customObjNodes: { ...state.customObjNodes, [id]: { ...state.customObjNodes[id], data: payload?.data } }
      };
    };
    /** system variables */
    case NODES_CONNECTED_VARS: {
      return {
        ...state,
        nodesConnectedWithSystemVars: payload,
      };
    }
    case NODES_UPDATE_CONNECTED_VARS: {
      let userSpace = { ...state?.nodesConnectedWithSystemVars }
      if (!isEmpty(payload?.newValue)) {
        if (payload?.newValue?.type === typeVariable.ARRAY || payload?.newValue?.type === typeVariable.OBJECT) {
          userSpace = {
            ...userSpace,
            [payload?.newValue?.title]: {
              ...userSpace?.[payload?.newValue?.title] || {},
              [payload?.newValue?.type]: {
                ...userSpace?.[payload?.newValue?.title]?.[payload?.newValue?.type] || {},
                [JSON.stringify(payload?.newValue?.arrKeys)]: {
                  ...userSpace?.[payload?.newValue?.title]?.[payload?.newValue?.type]?.[JSON.stringify(payload?.newValue?.arrKeys)] || {},
                  [payload?.nodeConditionId]: [
                    ...userSpace?.[payload?.newValue?.title]?.[payload?.newValue?.type]?.[JSON.stringify(payload?.newValue?.arrKeys)]?.[payload?.nodeConditionId] || [],
                    payload?.ruleId
                  ]

                }

              }
            }

          }
        } else {
          userSpace = {
            ...userSpace,
            [payload?.newValue?.title]: {
              ...userSpace?.[payload?.newValue?.title] || {},
              [payload?.newValue?.type]: {
                ...userSpace?.[payload?.newValue?.title]?.[payload?.newValue?.type] || {},
                [payload?.nodeConditionId]: [
                  ...userSpace?.[payload?.newValue?.title]?.[payload?.newValue?.type]?.[payload?.nodeConditionId] || [],
                  payload?.ruleId
                ]

              }
            }

          }
        }
      }
      if (!isEmpty(payload?.prevValue)) {
        if (payload?.prevValue?.type === typeVariable.ARRAY || payload?.prevValue?.type === typeVariable.OBJECT) {
          let ruleIdsArr = filter([...userSpace?.[payload?.prevValue?.title]?.[payload?.prevValue?.type]?.[JSON.stringify(payload?.prevValue?.arrKeys)]?.[payload?.nodeConditionId]], (item) => item !== payload?.ruleId)
          if (ruleIdsArr?.length > 0) {
            userSpace = {
              ...userSpace,
              [payload?.prevValue?.title]: {
                ...userSpace?.[payload?.prevValue?.title] || {},
                [payload?.prevValue?.type]: {
                  ...userSpace?.[payload?.prevValue?.title]?.[payload?.prevValue?.type] || {},
                  [JSON.stringify(payload?.prevValue?.arrKeys)]: {
                    ...userSpace?.[payload?.prevValue?.title]?.[payload?.prevValue?.type]?.[JSON.stringify(payload?.prevValue?.arrKeys)] || {},
                    [payload?.nodeConditionId]: ruleIdsArr
                  }

                }
              }

            }
          } else {
            delete userSpace?.[payload?.prevValue?.title]?.[payload?.prevValue?.type]?.[JSON.stringify(payload?.prevValue?.arrKeys)]?.[payload?.nodeConditionId]
            if (size(userSpace?.[payload?.prevValue?.title]?.[payload?.prevValue?.type]?.[JSON.stringify(payload?.prevValue?.arrKeys)]) === 0) {
              delete userSpace?.[payload?.prevValue?.title]?.[payload?.prevValue?.type]?.[JSON.stringify(payload?.prevValue?.arrKeys)]
              if (size(userSpace?.[payload?.prevValue?.title]?.[payload?.prevValue?.type]) === 0) {
                delete userSpace?.[payload?.prevValue?.title]?.[payload?.prevValue?.type]
              }
              if (size(userSpace?.[payload?.prevValue?.title]) === 0) {
                delete userSpace?.[payload?.prevValue?.title]
              }
            }

          }
        } else {
          let ruleIdsArr = filter([...userSpace?.[payload?.prevValue?.title]?.[payload?.prevValue?.type]?.[payload?.nodeConditionId] || []], (item) => item !== payload?.ruleId)
          if (ruleIdsArr?.length > 0) {
            userSpace = {
              ...userSpace,
              [payload?.prevValue?.title]: {
                ...userSpace?.[payload?.prevValue?.title] || {},
                [payload?.prevValue?.type]: {
                  ...userSpace?.[payload?.prevValue?.title]?.[payload?.prevValue?.type] || {},
                  [payload?.nodeConditionId]: ruleIdsArr
                }
              }

            }
          } else {
            delete userSpace?.[payload?.prevValue?.title]?.[payload?.prevValue?.type]?.[payload?.nodeConditionId]
            if (size(userSpace?.[payload?.prevValue?.title]?.[payload?.prevValue?.type]) === 0) {
              delete userSpace?.[payload?.prevValue?.title]?.[payload?.prevValue?.type]
              if (size(userSpace?.[payload?.prevValue?.title]) === 0) {
                delete userSpace?.[payload?.prevValue?.title]
              }
            }

          }
        }
      }
      return {
        ...state,
        nodesConnectedWithSystemVars: userSpace
      }
    };

    case DELETE_RULE_UPDATE_CONNECTED_VARS: {
      let userSpace = { ...state?.nodesConnectedWithSystemVars }
      if (payload?.nodeConditionId && payload?.ruleId) {
        if (payload?.systemVarTitle) {
          let ruleIdsArr = filter([...userSpace?.[payload?.systemVarTitle]?.[payload?.systemVarType]?.[payload?.nodeConditionId]], (item) => item !== payload?.ruleId)
          if (ruleIdsArr?.length > 0) {
            userSpace = {
              ...userSpace,
              [payload?.systemVarTitle]: {
                ...userSpace?.[payload?.systemVarTitle] || {},
                [payload?.systemVarType]: {
                  ...userSpace?.[payload?.systemVarTitle]?.[payload?.systemVarType] || {},
                  [payload?.nodeConditionId]: ruleIdsArr
                }
              }

            }
          } else {
            delete userSpace?.[payload?.systemVarTitle]?.[payload?.systemVarType]?.[payload?.nodeConditionId]
            if (size(userSpace?.[payload?.systemVarTitle]?.[payload?.systemVarType]) === 0) {
              delete userSpace?.[payload?.systemVarTitle]?.[payload?.systemVarType]
              if (size(userSpace?.[payload?.systemVarTitle]) === 0) {
                delete userSpace?.[payload?.systemVarTitle]
              }
            }

          }
        }
      }
      return {
        ...state,
        nodesConnectedWithSystemVars: userSpace
      };
    };
    /**relations nodes with jump nodes*/
    case NODES_CONNECTED_JUMP: {
      return {
        ...state,
        nodesConnectedWithJump: payload,
      };
    }
    case NODES_DELETE_GROUP_CONNECTED_JUMP: {
      let res = { ...state.nodesConnectedWithJump }
      delete res?.[payload?.currentNodeId];
      return {
        ...state,
        nodesConnectedWithJump: { ...res }
      }
    };
    case NODES_UPDATE_CONNECTED_JUMP: {
      let res = { ...state.nodesConnectedWithJump }

      if (!isEmpty(payload?.prevNodeId)) {

        if (includes(res[payload?.prevNodeId], payload?.jumpNodeId)) {
          res = { ...res, [payload?.prevNodeId]: [...res[payload?.prevNodeId]].filter((i: string) => i !== payload?.jumpNodeId) }
        }
      }

      res = { ...res, [payload?.currentNodeId]: [...res[payload?.currentNodeId] || [], payload?.jumpNodeId] }

      return {
        ...state,
        nodesConnectedWithJump: res,
      };
    };
    case NODES_DELETE_CONNECTED_JUMP: {
      return {
        ...state,
        nodesConnectedWithJump: { ...state.nodesConnectedWithJump, [payload?.currentNodeId]: [...state.nodesConnectedWithJump[payload?.currentNodeId]].filter((i: string) => i !== payload?.jumpNodeId) },
      };
    }
    // NODES_NOTIFIED
    case NODES_NOTIFIED: {
      return {
        ...state,
        notifiedNodes: payload,
      };
    }
    case GET_HTTP_REQUEST_USER_SPACE: {
      return {
        ...state,
        httpRequestUserSpace: payload?.httpRequestUserSpace,
        allVariables: payload?.allVariables
      };
    }
    case UPDATE_HTTP_REQUEST_USER_SPACE: {
      let resAllVariables = state?.allVariables
      if (has(payload, "allVariables")) {
        resAllVariables = payload?.allVariables
      }
      let resHttpRequestUserSpace = state?.httpRequestUserSpace
      if (has(payload, "httpRequestUserSpace")) {
        resHttpRequestUserSpace = payload?.httpRequestUserSpace
      }
      return {
        ...state,
        httpRequestUserSpace: resHttpRequestUserSpace,
        allVariables: resAllVariables
      };
    }
    case UPDATE_SHOW_DRAWER: {
      return {
        ...state,
        showDrawer: payload
      };
    }
    case UPDATE_SELECTED_CONDITION: {
      return {
        ...state,
        selectedCondition: payload
      };
    }
    case NODES_FIELDS_ERRORS: {
      return {
        ...state,
        errorsFieldsNodes: payload
      }
    }
    case UPDATE_NODES_FIELDS_ERRORS: {
      let res: any = { ...state.errorsFieldsNodes }
      if (!isEmpty(payload?.typeNode) && payload?.typeNode === "reply") {
        res[payload?.nodeId] = {
          ...res?.[payload?.nodeId],
          [payload?.activeMessageSettings]: {
            ...payload?.data
          }
        }
        if (size(res?.[payload?.nodeId]?.[payload?.activeMessageSettings]) === 0) {
          delete res?.[payload?.nodeId]
        }
        // if(payload?.type==="delete"){
        //   delete res?.[payload?.nodeId]?.[payload?.activeMessageSettings]?.[payload?.attr]
        //   if(size(res?.[payload?.nodeId]?.[payload?.activeMessageSettings])==0){
        //     delete res?.[payload?.nodeId]
        //   }
        // }
        // if(payload?.type==="add"){
        //   res[payload?.nodeId]={
        //     ... res?.[payload?.nodeId],
        //     [payload?.activeMessageSettings]:{
        //       ... res?.[payload?.nodeId]?.[payload?.activeMessageSettings],
        //       [payload?.attr]:payload?.msg
        //     }
        //   }
        // }
      } else {
        if (payload?.type === "delete") {
          delete res?.[payload?.nodeId]?.[payload?.attr]
          if (size(res?.[payload?.nodeId]) === 0) {
            delete res?.[payload?.nodeId]
          }
        }
        if (payload?.type === "add") {
          res[payload?.nodeId] = {
            ...res?.[payload?.nodeId],
            [payload?.attr]: payload?.msg
          }
        }
      }
      return {
        ...state,
        errorsFieldsNodes: res
      }
    }
    case UPDATE_NODES_FIELDS_ERRORS_REPLY: {
      let res: any = { ...state.errorsFieldsNodes }
      res[payload?.nodeId] = {
        ...res?.[payload?.nodeId],
        [payload?.activeMessageSettings]: { ...payload?.data }
      }
      if (size(res?.[payload?.nodeId]?.[payload?.activeMessageSettings]) === 0) {
        delete res?.[payload?.nodeId]
      }
      return {
        ...state,
        errorsFieldsNodes: res
      }
    }
    case ADD_FIELDS_ERRORS_REPLY: {
      let res: any = { ...state.errorsFieldsNodes }
      res[payload?.id] = {
        ...payload?.errorsFields?.[payload?.id] || {}
      }
      if (size(res[payload?.id]) === 0) {
        delete res?.[payload?.id]
      }
      return {
        ...state,
        errorsFieldsNodes: res
      }
    }
    case UPDATE_FLOW_OPTIONS: {
      return {
        ...state,
        currentFlowOptions: payload
      }
    }
    case GET_ORDERED_NODES: {
      return {
        ...state,
        orderedNodesObj: payload
      }
    }
    case UPDATE_ORDERED_NODES: {
      let errorsRelatedVariable = {}
      let nodesIds = keys(state.orderedNodesObj?.[payload?.targetFlowId]) || []
      let entriesNodes = Object.entries(state?.orderedNodesObj?.[payload?.targetFlowId] || {})
      let targetNodeIndex = findIndex(nodesIds, (nodeId) => nodeId === payload.targetNodeId)
      let entriesData = Object.entries(payload?.data || {})

      let resNodes = [
        ...entriesNodes.slice(0, targetNodeIndex + 1),
        ...entriesData,
        ...entriesNodes.slice(targetNodeIndex + 1)
      ];
      let res: any = Object.fromEntries(resNodes)
      map([...payload?.dataDeletedNodesId || []], (item) => {
        delete res[item]
      })
      let allVariables = { ...state.allVariables }
      if (payload?.dataDeletedNodesId?.length > 0) {
        mapKeys(allVariables, (_, titleKey: string) => {
          mapKeys(allVariables?.[titleKey], (_, typeKey) => {
            if (typeKey === typeVariable.ARRAY || typeKey === typeVariable.OBJECT) {
              mapKeys(allVariables?.[titleKey]?.[typeKey], (_, arrKey) => {
                map([...payload?.dataDeletedNodesId], (item) => {
                  delete allVariables?.[titleKey]?.[typeKey]?.[arrKey]?.[item]
                })
                if (size(allVariables?.[titleKey]?.[typeKey]?.[arrKey]) === 0) {
                  delete allVariables?.[titleKey]?.[typeKey]?.[arrKey]
                }
                if (size(allVariables?.[titleKey]?.[typeKey]) === 0) {
                  delete allVariables?.[titleKey]?.[typeKey]
                }
              })
            } else {
              map([...payload?.dataDeletedNodesId], (item) => {
                delete allVariables?.[titleKey]?.[typeKey]?.[item]
              })
              if (size(allVariables?.[titleKey]?.[typeKey]) === 0) {
                delete allVariables?.[titleKey]?.[typeKey]
              }
            }
          })
          if (size(allVariables?.[titleKey]) === 0) {
            delete allVariables?.[titleKey]
          }
        })
      }
      let newErrorsArr = { ...state.errorsRelatedVariables }
      /** when copying node has connections with variables(node with one branch), should apply processing on variables */
      if (!isEmpty(payload?.copiedNode)) {
        const { data, type, id } = payload?.copiedNode
        if (includes([types.FILTER, types.MAP, types.SORT], type)) {
          let isNotFound = false;
          if (!has(allVariables, data?.sourceArray?.title)) {
            isNotFound = true;
          } else {
            let nodesIdArr = keys(
              allVariables?.[data?.sourceArray?.title]?.[
              data?.sourceArray?.type
              ]?.[JSON.stringify(data?.sourceArray?.arrKeys)]
            );
            isNotFound = handleVarFound(id, res, nodesIdArr);
            if (isNotFound) {
              errorsRelatedVariable = {
                [id]: {
                  rulesId: [id],
                  reason: {
                    [data?.sourceArray?.title]: {
                      [data?.sourceArray?.type]: JSON.stringify(
                        data?.sourceArray?.arrKeys
                      ),
                    },
                  },
                },
              };
            }
          }
        }
        let userSpaceVar = Object.values(payload?.userSapce)
        if (type === types.HTTP_REQUEST) {
          const { responseBodyVars = [], responseErrorVars = [] } = state.httpRequestUserSpace?.[id]
          userSpaceVar = [...responseBodyVars, responseErrorVars]
        }
        let nodesHadeErrorVar = getNodesHadeErrorsRelatedVars(
          userSpaceVar,
          id,
          res,
          state.nodesConnectedWithSystemVars,
          state.errorsRelatedVariables
        );
        if (size(nodesHadeErrorVar) > 0) {
          newErrorsArr = onExtractErrors(nodesHadeErrorVar, newErrorsArr)
        }
      }
      let errorsRelatedVariables = {
        ...newErrorsArr,
        ...errorsRelatedVariable
      }
      return {
        ...state,
        orderedNodesObj: { ...state?.orderedNodesObj, [payload?.targetFlowId]: res },
        allVariables,
        errorsRelatedVariables
      }
    }
    case UPDATE_ORDERED_NODES_SUB_FLOWS: {
      return {
        ...state,
        orderedNodesObj: { ...state?.orderedNodesObj, [payload?.targetFlowId]: payload?.data }
      }
    }
    case CUSTOM_LOADING: {
      return {
        ...state,
        customLodaer: true
      }
    }
    case CUSTOM_STOP_LOADING: {
      return {
        ...state,
        customLodaer: false
      }
    }
    case DISPLAY_ERROR_MODAL: {
      return {
        ...state,
        isDisplayErrorModal: true
      }
    }
    case STOP_DISPLAY_ERROR_MODAL: {
      return {
        ...state,
        isDisplayErrorModal: false
      }
    }
    case UPDATE_ALL_VARIABLES: {
      let newAllVars = { ...state.allVariables }
      mapKeys(payload, (variable, variableKey) => {
        mapKeys(variable, (itemType, itemTypeKey) => {
          if (includes([typeVariable.ARRAY, typeVariable.OBJECT], itemTypeKey)) {
            mapKeys(itemType, (itemArr, itemArrKeys) => {
              newAllVars = {
                ...newAllVars,
                [variableKey]: {
                  ...newAllVars?.[variableKey] || {},
                  [itemTypeKey]: {
                    ...newAllVars?.[variableKey]?.[itemTypeKey] || {},
                    [itemArrKeys]: {
                      ...newAllVars?.[variableKey]?.[itemTypeKey]?.[itemArrKeys] || {},
                      ...itemArr
                    }

                  }

                }
              }
            })

          } else {
            newAllVars = {
              ...newAllVars,
              [variableKey]: {
                ...newAllVars?.[variableKey] || {},
                [itemTypeKey]: {
                  ...newAllVars?.[variableKey]?.[itemTypeKey] || {},
                  ...itemType
                }
              }
            }
          }
        })
      })
      return {
        ...state,
        allVariables: { ...newAllVars }
      }
    }
    case ADD_HTTP_REQUEST_USER_SPACE: {
      return {
        ...state,
        httpRequestUserSpace: {
          ...state.httpRequestUserSpace,
          [payload?.id]: {
            ...payload?.data
          }
        }
      }
    }
    case NODES_CONNECTED_SUB_FLOW: {
      return {
        ...state,
        nodesConnectedWithSubFlows: payload,
      };
    }
    case UPDATE_NODES_CONNECTED_SUB_FLOW: {
      let resNodesConnectedWithSubFlows: { [subFlowId: string]: subFlowsUsageData } = { ...state.nodesConnectedWithSubFlows }
      let resTargetFlowCallee = [...resNodesConnectedWithSubFlows[payload?.targetFlowId]?.callee || []]
      let resTargetFlowNodes = { ...resNodesConnectedWithSubFlows[payload?.targetFlowId]?.nodes || {} }
      let resCurrentCalledFlow: { [subFlowId: string]: subFlowsUsageData } = {}
      let resPrevCalledFlow: { [subFlowId: string]: subFlowsUsageData } = {}
      if (!isEmpty(payload?.prevCalledFlow)) {
        //should delete targetFlowId from resPrevCalledFlow's caller, if the targetFlowId contain one node connectes with resPrevCalledFlow 
        if (Object.values(resTargetFlowNodes || []).filter(item => item === payload?.prevCalledFlow?.id).length === 1) {
          resPrevCalledFlow = { [payload?.prevCalledFlow?.id]: { ...resNodesConnectedWithSubFlows[payload?.prevCalledFlow?.id] } }
          let resCaller = filter([...resNodesConnectedWithSubFlows[payload?.prevCalledFlow?.id]?.caller || []], (i) => i !== payload?.targetFlowId)
          resTargetFlowCallee = filter(resTargetFlowCallee, (i) => i !== payload.prevCalledFlow?.id)

          resPrevCalledFlow = {
            [payload?.prevCalledFlow?.id]: {
              ...resPrevCalledFlow?.[payload?.prevCalledFlow?.id],
              caller: [...resCaller]
            }
          }
        }
        if (isEmpty(payload?.currentCalledFlow)) delete resTargetFlowNodes[payload?.targetNodeId]
      }
      if (!isEmpty(payload?.currentCalledFlow)) {
        resCurrentCalledFlow = { [payload?.currentCalledFlow?.id]: { ...resNodesConnectedWithSubFlows?.[payload?.currentCalledFlow?.id] || {} } }
        resTargetFlowCallee.push(payload?.currentCalledFlow?.id)
        resTargetFlowNodes[payload?.targetNodeId] = payload?.currentCalledFlow?.id
        resCurrentCalledFlow = {
          [payload?.currentCalledFlow?.id]: {
            ...resCurrentCalledFlow?.[payload?.currentCalledFlow?.id] || {},
            caller: uniq([...resCurrentCalledFlow?.[payload?.currentCalledFlow?.id]?.caller || [], payload?.targetFlowId])
          }
        }
      }
      resNodesConnectedWithSubFlows = {
        ...resNodesConnectedWithSubFlows,
        [payload?.targetFlowId]: {
          ...resNodesConnectedWithSubFlows?.[payload?.targetFlowId],
          nodes: { ...resTargetFlowNodes },
          callee: [...uniq(resTargetFlowCallee)]
        },
        ...resPrevCalledFlow,
        ...resCurrentCalledFlow
      }
      return {
        ...state,
        nodesConnectedWithSubFlows: { ...resNodesConnectedWithSubFlows },
        customLodaer: true
      };
    }
    case DELETE_NODES_CONNECTED_SUB_FLOW: {
      let resNodesConnectedWithSubFlows: { [subFlowId: string]: subFlowsUsageData } = { ...state.nodesConnectedWithSubFlows }
      map(payload?.dataDeletedNodesId, (subFlowNodeId: string) => {
        let targetFlowNodes = { ...resNodesConnectedWithSubFlows?.[payload?.targetFlowId]?.nodes || {} }
        let targetFlowCallee = [...resNodesConnectedWithSubFlows?.[payload?.targetFlowId]?.callee || []]

        if (Object.hasOwn(targetFlowNodes, subFlowNodeId)) {
          let subFlowValue: string = targetFlowNodes?.[subFlowNodeId] || ""
          delete targetFlowNodes?.[subFlowNodeId]
          if (!includes(Object.values(targetFlowNodes || []), subFlowValue)) {
            // console.log("targetFlowNodes doen't have any node connected with subFlowValue, should remove subFlowValue from callee")
            // console.log("should remove targetFlowId from caller found in subFlowValue")
            targetFlowCallee = filter(resNodesConnectedWithSubFlows?.[payload?.targetFlowId]?.callee || [], (item) => item !== subFlowValue)

            resNodesConnectedWithSubFlows = {
              ...resNodesConnectedWithSubFlows,
              [subFlowValue]: {
                ...resNodesConnectedWithSubFlows?.[subFlowValue],
                caller: filter([...resNodesConnectedWithSubFlows?.[subFlowValue]?.caller || []], (item) => item !== payload?.targetFlowId)
              }
            }
          }
          resNodesConnectedWithSubFlows = {
            ...resNodesConnectedWithSubFlows,
            [payload?.targetFlowId]: {
              ...resNodesConnectedWithSubFlows?.[payload?.targetFlowId],
              nodes: targetFlowNodes,
              callee: targetFlowCallee
            }
          }

        }

      })
      return {
        ...state,
        nodesConnectedWithSubFlows: resNodesConnectedWithSubFlows
      }
    }

    case DISPLAY_TEMPLATE_MODAL: {
      return {
        ...state,
        isDisplayTemplateModal: true
      }
    }
    case STOP_DISPLAY_TEMPLATE_MODAL: {
      return {
        ...state,
        isDisplayTemplateModal: false
      }
    }
    default:
      return state;
  }

}